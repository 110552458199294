<template>
  <div
    :class="`
    w-full 
    flex 
    flex-col 
    min-h-[100dvh]
  `"
  >
    <SiteHeaderContainer class="h-14">
      <div class="flex items-center gap-2 shrink-0">
        <NuxtLink class="flex items-center cursor-pointer" to="/">
          <Logo :mark-only="false" />
        </NuxtLink>
      </div>
      <div class="ml-auto flex items-center gap-4 sm:gap-8 shrink"></div>
    </SiteHeaderContainer>

    <main class="flex justify-center flex-1 h-full overflow-clip -mt-14 pt-12">
      <slot />
    </main>
    <MarketingSiteFooter />
  </div>
</template>
